.main-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 85vh;
    padding-top: 15vh;
    position: relative;
    width: 100%;
}

.writings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 15vh;
}

.hello {
    font-size: 1.5rem;
    font-weight: 500;
    display: inline-block;
    padding: 10px 20px;
    border: 3px solid #e67e22;
    border-radius: 15px; 
    color: #fff; 
}

.myname {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding: 0.5% 1% 0% 1%;
    z-index: -1; 
}

.myname1 {
    font-size: 4rem;
    font-weight: 500;
    margin: 0;
    line-height: 1.1;
    color: #fff;
    transition: color 0.3s;
}

.myname2 {
    font-size: 4.3rem;
    font-weight: 750;
    margin: 0;
    line-height: 1.1;
    color: #de8232;
    text-shadow: 0px 0px 25px rgba(243, 156, 18, 0.4), 0.2rem 0.2rem black;
    transition: color 0.3s;
}

.myname2:hover {
    color: #e67e22;
}

.space {
    padding: 0.8rem
}

.myname3 {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
    line-height: 1.1;
    text-shadow: 0.3rem 0.3rem black;
    color: #BABABA;
}

.intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.text-container {
    flex: 1;
    min-width: 200px;
}

.intro-text {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
}

.intro-name {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
    line-height: 1.1;
    color: #f39c12;
    transition: color 0.3s;
}

.intro-name:hover {
    color: #e67e22;
}

.intro-title {
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.image-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.profile-image-dot {
    height: 70vh;
    width: auto;  /* Keeps the aspect ratio */
}

.citation {
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    max-width: 320px;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 1;
    line-height: 1.8;
    background: rgba(20, 20, 20, 0.9);
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.3);
    transform: perspective(1000px) rotateY(15deg);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    overflow: hidden;
}

.citation::before {
    content: "“";
    font-size: 8rem;
    color: rgba(230, 126, 34, 0.2);
    position: absolute;
    left: -20px;
    top: -40px;
    font-family: 'Georgia', serif;
    transition: all 0.3s ease;
}

.citation::after {
    content: "”";
    font-size: 8rem;
    color: rgba(230, 126, 34, 0.2);
    position: absolute;
    right: -20px;
    bottom: -60px;
    font-family: 'Georgia', serif;
    transition: all 0.3s ease;
}

.citation:hover {
    transform: perspective(1000px) rotateY(0deg) translateY(-5px);
    box-shadow: 0 15px 40px rgba(0,0,0,0.4);
    background: rgba(20, 20, 20, 1);
}

.citation:hover::before,
.citation:hover::after {
    color: rgba(230, 126, 34, 0.4);
    text-shadow: 0 0 15px rgba(230, 126, 34, 0.3);
}

.citation br {
    display: block;
    margin: 1.2rem 0;
    content: " ";
}

/* Gradient border effect */
.citation::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(45deg, #e67e22 0%, transparent 70%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}


/* -----------------------------
Scroll Button Container
----------------------------- */
/* Positioned so it doesn’t conflict with other elements.
You can adjust bottom/right as needed. */
.scroll-button-container {
    position: absolute;
    bottom: 15%;
    right: 10%;
    z-index: 2;
}

/* -----------------------------
Scroll Button Styled Like Citation
----------------------------- */
.scroll-button {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    padding: 1.5rem 2rem;
    background: rgba(20, 20, 20, 0.7);
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    border: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

/* Gradient border effect, similar to the citation's */
.scroll-button::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(45deg, #e67e22 0%, transparent 70%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

  /* Hover effects similar to citation */
.scroll-button:hover {
    transform: perspective(1000px) rotateY(0deg) translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
    background: rgba(20, 20, 20, 1);
}

/* Arrow icon styling */
.arrow-icon {
    margin-top: 0.5rem;
    font-size: 2rem;
}

/* Media queries for responsiveness */
@media (max-width: 1100px) {
    .main-content {
        height: 90vh;
        padding-top: 10vh;
    }

    .writings {
        top: 10vh;
    }

    .myname1, .myname2, .myname3 {
        font-size: 5vh;
    }

    .space{
        padding: 0.2rem;
    }
    
    .citation {
        font-size: 2vh;
        max-width: 80%;
        padding: 3vh;
        top: 25vh;
        z-index: -5;
    }

    .image-container {
        left: 50%;
        transform: translateX(-50%);
    }

    .profile-image-dot {
        height: 50vh;
        padding: 0 10rem;
    }
    
    .scroll-button {
        font-size: 1rem;
        padding: 1rem;
    }
    
    .scroll-button-container {
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
    }
}