/* Background and typography */
.experience-bg {
    padding: 2.5rem 0;
    color: #fff;
    font-family: 'Roboto', sans-serif;
}

/* Header styling */
.experience-header {
    text-align: center;
    margin-bottom: 3rem;
    padding: 0 1rem;
}

.experience-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
}

.title-orange {
    color: #de8232;
}

.experience-description {
    margin-top: 2rem;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.125rem;
    color: #fff;
}

/* Container for experience items */
.experience-container {
    max-width: 65%;
    margin: 0 auto;
    padding: 0 1rem;
}

/* Individual experience item styling */
.experience-item {
    background-color: #1c1c1c;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease;
}

.experience-item:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.experience-item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.experience-item-title {
    font-size: 1.9rem;
    color: #de8232;
    margin: 0;
}

.experience-item-organization {
    color: #c0c0c0;
    margin: 0.25rem 0 0;
}

.experience-item-period {
    font-size: 1rem;
    color: #8a8a8a;
}

/* Highlights list */
.experience-highlights {
    list-style-type: disc;
    padding-left: 20px;
}

.experience-highlight {
    margin-bottom: 0.75rem;
    color: #fff;
    display: flex;
    align-items: start;
    font-size: 1.1rem;
}

.highlight-icon {
    color: #de8232;
    margin-right: 0.5rem;
}

/* -----------------------------
Scroll Button Container
----------------------------- */
/* Positioned so it doesn’t conflict with other elements.
You can adjust bottom/right as needed. */
.button-container {
    display: flex;
    justify-content: center;
}

/* -----------------------------
Scroll Button Styled Like Citation
----------------------------- */
.button {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    padding: 1.5rem 2rem;
    background: rgba(20, 20, 20, 0.7);
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    border: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

/* Gradient border effect, similar to the citation's */
.button::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(45deg, #e67e22 0%, transparent 70%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

  /* Hover effects similar to citation */
.button:hover {
    transform: perspective(1000px) rotateY(0deg) translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
    background: rgba(20, 20, 20, 1);
}

/* Arrow icon styling */
.arrow-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.5rem;
}

@media (max-width: 1100px) {
    .experience-container {
        max-width: 90%;
    }

    .experience-item-title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
    .experience-item-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .experience-item-period {
        margin-top: 0.5rem;
        font-size: 0.8rem;
    }

    .experience-highlight {
        font-size: 0.9rem;
    }
}