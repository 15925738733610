.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #141414;
    position: fixed;
    width: 100%;
    height: 10vh;
    z-index: 127;
    left: 0; /* Explicitly set left to 0 */
    top: 0; /* Explicitly set top to 0 */
    box-sizing: border-box; /* Include padding in width calculation */
}

.left-header{
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 2rem;
    margin-top: auto;
}

.social-icons {
    display: flex;
}

.social-icon {
    margin: 0 0.5rem;
    color: #fff;
    font-size: 1.9rem;
    transition: all 0.3s ease-in-out; 
}

.social-icon:hover {
    color: #de8232;
    transform: scale(1.15); /* Slight zoom effect */
}

.menu-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

nav ul {
    padding: 0.5rem 2rem;
    display: flex;
    list-style: none;
    margin: 0;
}

/* Base navigation item */
nav ul li {
    margin-top: 1%;
    margin-left: 20px;
    font: 1.3rem 'Poppins', sans-serif;
    font-weight: 540;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out; /* Smooth transition for all properties */
}

/* Hover effect */
nav ul li:hover {
    background-color: #de8232;
    color: #fff;
    border-radius: 10px;
    transform: scale(1.05); /* Slight zoom effect */
}

/* Active section highlighting */
nav ul li.active {
    background-color: #de8232;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(243, 156, 18, 0.6); /* Stronger shadow for active item */
    transform: scale(1.1); /* More noticeable zoom */
}

/* Optional: Underline slide-in effect for hover and active */
nav ul li::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 3px;
    background-color: #de8232;
    left: 0;
    bottom: -5px;
    transition: width 0.3s ease-in-out;
}

nav ul li:hover::after {
    width: 100%; /* Underline expands on hover and active */
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}

/* Add this to prevent layout shift in body content */
body {
    padding-top: 10vh; /* Match the header height */
    margin: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (max-width: 1100px) {
    .header {
        height: 7vh; /* Reduced height for mobile */
        padding: 0 10px; /* Add some padding to prevent content from touching edges */
    }
    
    body {
        padding-top: 7vh; /* Match the mobile header height */
    }
    
    .menu-toggle {
        display: block;
        margin-right: 10px; /* Add some spacing */
    }

    .left-header {
        padding: 0.5rem;
        width: auto; /* Let it take natural width */
    }

    .logo {
        font-size: 1rem;
        margin-right: 1rem;
    }

    .social-icon {
        margin: 0 0.3rem;
        font-size: 1rem;
    }

    nav {
        display: none;
        width: 100%;
        position: fixed; /* Change from absolute to fixed */
        top: 7vh; /* Position right below the header */
        left: 0;
        right: 0;
        background-color: #141414;
        max-height: 93vh; /* Ensure it doesn't go beyond viewport */
        overflow-y: auto; /* Allow scrolling if many menu items */
        box-sizing: border-box; /* Include padding in width calculation */
    }

    nav.open {
        display: block; /* Change from flex to block */
    }

    nav ul {
        flex-direction: column;
        padding: 0.5rem;
    }

    nav ul li {
        margin: 0.5rem 0;
        text-align: center;
        width: calc(100% - 1rem); /* Make menu items full width accounting for padding */
    }
}

/* Add this to ensure everything loads with the correct initial state */
html {
    overflow-x: hidden;
    width: 100%;
}