.projects-container {
    background-color: rgb(26, 26, 26);
    padding: 20px;
    margin: 0 auto;
    font-family: 'Lucida Console', monospace;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
}

.projects-header {
    color: #fff;
    text-align: center;
    margin: 3rem 2rem;
    font-size: 4rem;
    font-family: 'roboto', sans-serif;
    font-weight: bold;
}

.title-orange {
    color: #de8232;
}

.projects-header2 {
    color: #de8232;
    text-align: center;
    margin: 1rem 2rem;
    font-size: 3.5rem;
    font-family: 'roboto', sans-serif;
    font-weight: bold;
}


.intro {
    margin: 1rem 5rem;
}

.intro-text {
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 1.6;
}

.bold-text {
    font-weight: 600;
    color: #f39c12;
}

.projects-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1.5rem; 
    padding: 2rem;
    justify-content: center;
}

.project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #444;
    border-radius: 12px;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 5px 5px #000; 
    transition: transform 0.2s, box-shadow 0.3s;
    min-height: 480px; /* Uniform height */
}

.project-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(243, 156, 18, 0.3);
    border: 2px solid #f39c12;
}

.project-title {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #f39c12;
    text-align: center;
}

.project-image {
    width: 100%;
    height: 250px; /* Adjusted to keep aspect ratio */
    object-fit: cover;
}

.project-description {
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ddd;
    text-align: center;
    flex-grow: 1; /* Ensures descriptions align well */
}

.pagination {
    display: flex;
    justify-content: center; /* Center pagination items horizontally */
    margin-top: 2rem; /* Add some space above the pagination */
}

.page-item {
    cursor: pointer; /* Change cursor to pointer on hover */
    padding: 0.5rem 1rem; /* Add padding around pagination numbers */
    margin: 0 0.25rem; /* Add spacing between pagination items */
    font-size: 1rem; /* Adjust font size */
    border-radius: 0.25rem; /* Rounded corners */
    background-color: #f0f0f0; /* Light background for pagination items */
    color: #333; /* Default text color */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.page-item.active {
    background-color: #f39c12; /* Highlighted background color */
    color: #fff; /* Text color for active page */
    font-weight: bold; /* Make active page number bold */
}

.page-item:hover {
    background-color: #595959; /* Slightly darker background on hover */
}


/* Media queries for responsiveness */
@media (max-width: 1100px) {
    .projects-container {
        padding: 1rem;
        border-radius: 0;
    }

    .projects-header {
        font-size: 4.5vh;
    }

    .projects-content {
        padding: 0;
    }

    .intro {
        margin: 1rem;
    }

    .intro-text {
        font-size: 1rem;
    }

    .project-description {
        font-size: 0.9rem;
    }
}
