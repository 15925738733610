.about-container {
    background-color: rgb(26, 26, 26);
    padding: 20px;
    margin: 0 auto;
    font-family: 'Lucida Console', monospace;
    border-radius: 5%;
}

.about-header {
    color: #de8232;
    text-align: center;
    margin: 6rem 2rem 0 2rem;
}

.about-header p {
    color:#e67e22;
    font-size: 2rem;
    font-weight: 500;
    border: #e67e22 2px solid;
    border-radius: 10px;
    margin: 0 10rem;
    padding: 1rem
}

.about-header h1 {
    font-size: 4rem;
    font-family: 'roboto', sans-serif;
    font-weight: bold;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #141414;
}

.about-content {
    color: #fff;
    font-size: 1.2rem;
    margin: 2rem 12rem;
    word-spacing: 0.5rem;
    line-height: 2rem;
    
}

h2 {
    border-bottom: 2px solid #e67e22;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.about-item, .education-item {
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

@media (max-width: 1100px) {
    .about-container {
        padding: 2vh;
    }

    .about-header {
        margin: 2% 1% 0 1%;

    }

    .about-header p {
        margin: 0 5%;
        font-size: 4vh;
    }

    .about-header h1 {
        font-size: 8vh;
    }

    .about-content {
        margin: 2% 5%;
        font-size: 2vh;
    }

}