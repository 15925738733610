body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #1f1f1f;
  color: #fff;
}

.App {
  text-align: center;
}

