.project-details {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    align-items: center;
}

.project-details h1 {
    font-size: 2rem;
    color: #f39c12;
    text-align: center; /* Center align text */
}

.project-details h2 {
    color: #f39c12;
}

.project-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.project-description {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4;
    color: #ddd;
    text-align: center; /* Center align text */
}

.no-project {
    text-align: center;
    padding: 2rem;
}

@media (max-width: 1100px) {
    .project-details {
        padding: 1.5rem;
    }

    .project-details h1 {
        font-size: 1.5rem;
    }

    .project-details h2 {
        font-size: 1.2rem;
    }

    .project-description {
        font-size: 0.9rem;
    }
}
